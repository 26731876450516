import React from 'react'

// Not found page
const NotFoundPage = () => (
  <div style={{ textAlign: "center" }}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </div>
)

export default NotFoundPage
